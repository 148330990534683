<template>
    <GmapMap
    :center="{lat:lat, lng:lng}"
    :zoom="7"
    map-type-id="terrain"
    style="width: 100%; height: 100px; margin: 10px 0"
    :options="{
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    disableDefaultUi: false
    }"
    >
        <!-- <GmapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @click="center=m.position"

        /> -->
    </GmapMap>
</template>

<script>
export default {
    props: [
        'loc'
    ],
    mounted(){
        
    },
    data() {
        return {
            lat: this.loc?parseInt(this.loc.latitude) : parseInt(-9.394359713836463),
            lng: this.loc?parseInt(this.loc.longitude) : parseInt(-54.174285879094825),
        }
    }
}
</script>

<style>

</style>